import { Col, Layout, Row, Space } from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import cart from '../images/grocery-cart.jpg';
import './App.css';
import Login from './Login';

const App = () => {
  return (
    <Layout className='app-background'>
      <Header className='app-header'>
        <Row justify="center">Auto Shopper</Row>
      </Header>
      <Layout>
        <Content className='app-content'>
          <Space direction="vertical" size={75}>
            <Row justify="center">
              <img className='app-logo' src={cart} />
            </Row>
            <Row justify="center">
              <Login />
            </Row>
          </Space>
        </Content>
      </Layout>
      <Footer className='app-footer'>
        <Row justify="center">Copyright &#169; 2021 Autoshopper, All Rights Reserved.</Row>
      </Footer>
      
    </Layout>
  );
};

export default App;
